@font-face {
  font-family: "segment-mono";
  src: url("segment-mono.ttf") format("truetype");
}
@font-face {
  font-family: "segment";
  src: url("segment.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');

.App {
  text-align: center;
}

a, .pressable {
  transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
}
a:hover, .pressable:hover {
  opacity: 0.7;
  filter: hue-rotate(-30deg);
}

@keyframes log-anim {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-150vh);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-up {
  from {
    transform: translateY(150vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(150vw);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes marquee {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-50%);
  }
}

@keyframes unblur-camera {
  from {
    opacity: 0.2;
    filter: blur(10px);
    webkit-filter: blur(10px);
  }
  to {
    opacity: 0.6;
    @media (orientation: landscape) {
      filter: blur(0.5px);
      webkit-filter: blur(0.5px);
    }
    @media (orientation: portrait) {
      filter: blur(0px);
      webkit-filter: blur(0px);
    }
  }
}

@keyframes static-idle {
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: 100px;
  }
}

@keyframes bg-focus {
  0% {
    filter: blur(0px);
    webkit-filter: blur(0px);
  }
  30% {
    filter: blur(10px);
    webkit-filter: blur(10px);
  }
  50% {
    filter: blur(0);
    webkit-filter: blur(0);
  }
  70% {
    filter: blur(5px);
    webkit-filter: blur(5px);
  }
  100% {
    filter: blur(0px);
    webkit-filter: blur(0px);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  58% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
